<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import { required } from "vuelidate/lib/validators";
import helpers from '../../../helpers'

import {
  personalShopperMethods
} from "@/state/helpers";

export default {
  page: {
    title: "Personal Shoppers",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    
  },
  data() {
    return {
      title: "",
      items: [],
			currentPage:1,
			perPage:10,
      rows:0,
			isLoading:false,
      shoppers:[],
      confirmDelete:false,
      ps: {},
      query:'',
      empty_list_config:{
            "title" : this.$t('personal_shoppers.empty_search_title'),
            "linkButtonText" : this.$t("common.reset_search"),
            icon: "fa-cubes"
        },
        empty_config:{
            "title" : this.$t('personal_shoppers.empty_title'),
            "subtitle" : this.$t('personal_shoppers.empty_text'),
            "buttonText" : this.$t('personal_shoppers.title'),
            icon: "fa-cubes"
        },
        isLocal: window.config.env == 'dev',
        showNewPersonalShopperModal: false,
        submitted: false
    };
  },
  validations: {
		ps: {
			name: {
				required,
			},
      description: {
				required,
			},
    },
	},
  created(){
		this.debouncePersonalShoppers = _debounce(this.searchPersonalShoppers.bind(this), 1000);
	},
	mounted(){
		this.loadPersonalShoppers();
  
  },
  methods:{
    ...personalShopperMethods,
    ...helpers,

    onRemovePersonalShopper(ps){
      this.ps = ps;
      this.confirmDelete = true;
    },
    onConfirmRemovePersonalShopperClicked(){
      let loader = this.$loading.show();

      this.deletePersonalShopper(this.ps._id).then(()=>{
        this.currentPage=1;
        this.loadPersonalShoppers();
        this.$notify({ type: 'success', text: this.$t('personal_shoppers.remove_personal_shoppers_success'), title: this.$t('personal_shoppers.title') });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('personal_shoppers.remove_personal_shoppers_error'),title: this.$t('personal_shoppers.title') });
      }).finally(()=>{
        loader.hide();
      })
    },

		loadPersonalShoppers(){
			
      let loader = this.$loading.show();
      this.isLoading = true;
      
      const params = {
				q : `where[project]=${localStorage.getItem("current_project")}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
			}

			this.getPersonalShoppers(params).then((result)=>{
        if(result&& result.data){
          this.shoppers= result.data;
          this.rows= result.totalCount
				}
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('personal_shoppers.get_personal_shoppers_error'), title: this.$t('personal_shoppers.title')   });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },
    
    searchPersonalShoppers(query){
      
			let loader = this.$loading.show();
      this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("personal_shoppers.empty_search_text").replaceAll('#', this.query);

      if(query){
        this.shoppers=[];
        this.currentPage = 1;
				const params={
					q: `where[project]=${localStorage.getItem("current_project")}&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
				}

        this.getPersonalShoppers(params).then((result)=>{
          if(result&& result.data){
            this.shoppers= result.data;
            this.rows= result.totalCount;
          }
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('personal_shoppers.get_personal_shoppers_error'), title: this.$t('personal_shoppers.title')   });
        }).finally(()=>{
          this.isLoading = false;
          loader.hide();
        });
      }else{
        this.isLoading = false;
        this.currentPage = 1;
        loader.hide();
        this.loadPersonalShoppers();
      }
    },
		onPersonalShopperListPageClicked(){
			this.loadCustomStrategies();
		},	
    onEditPersonalShopperClicked(data){
      this.$router.push({
            path: `/personal-shopper?id=${data._id}`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    onResetSearchClicked(){
      this.query = '';
      this.loadPersonalShoppers();
    },
    onNewPersonalShopperClicked(){
      this.submitted = false;
      this.ps = {
        goal: {
          _id: 'purchase',
          time: 24
        }
      }
      this.showNewPersonalShopperModal = true
    },
    onChangePersonalShopperStatus(data){
      let loader = this.$loading.show();
      const payload = {
        _id: data._id,
        status : !data.status
      }
      this.updatePersonalShopper(payload).then(()=>{
          this.$notify({ type: 'success', text: this.$t('personal_shoppers.update_personal_shopper_success'),title:  this.$t('personal_shoppers.title') });
          this.loadPersonalShoppers();
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('personal_shoppers.update_personal_shopper_error'),title:  this.$t('personal_shoppers.title') });
        }).finally(()=>{
          loader.hide();
        })
    },
    onConfirmNewPersonalShopperClicked(){

      this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

      const payload = {
        name: this.ps.name,
        description: this.ps.description,
        status: 0,
        project: localStorage.getItem('current_project')
      }
      
      let loader = this.$loading.show();

      this.createPersonalShopper(payload).then(()=>{
        this.$notify({ type: 'success', text: this.$t("personal_shoppers.create_personal_shopper_success"),title:  this.$t("personal_shoppers.title") });

        this.showNewPersonalShopperModal = false;
        this.currentPage= 1;
        this.loadPersonalShoppers();
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("personal_shoppers.create_personal_shopper_error"),title:  this.$t("personal_shoppers.title") });
      }).finally(()=>{
        loader.hide();
      })
    
  }
}
};
</script>

<template>
  <Layout>
    <PageHeader :title="this.$t('personal_shoppers.title')" :items="items" />
    <div class="row">
      <div class = "col">
        <div class="card mb-3">
				<div class="card-body">
          <div class="row ">
            <div class="col-sm-4 d-inline-flex">
                <div class="search-box me-2 mb-0 d-inline-block">
                  <div class="position-relative">
                      <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('common.search')"
                      @input="debouncePersonalShoppers($event.target.value)"
                      v-model="query"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
            </div>
            <div class="col text-sm-end">
              <button
                      type="button"
                      class="btn btn-primary mb-0 me-0"
                      @click="onNewPersonalShopperClicked">
                  <i class="mdi mdi-plus me-1"></i> {{$t('personal_shoppers.new')}}
              </button>
            </div>
          </div>
          <EmptyList :config="empty_list_config" v-if="!isLoading && shoppers.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
          <EmptyList :config="empty_config" v-if="!isLoading && shoppers.length == 0 && query ==''" @onButtonClicked="onNewPersonalShopperClicked" class="mt-3 mb-3"/>
        </div>
          <div class="col-lg-12" v-if="!isLoading && shoppers.length>0">
            <div>
              <div class="table-responsive">
                <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">{{$t('personal_shoppers.name')}}</th>
                      <th scope="col" class="text-center">{{$t('personal_shoppers.last_sync')}}</th>
                      <th scope="col" class="text-center">{{$t('custom_strategies.status')}}</th>
                      <th scope="col" class="text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in shoppers" :key="data.id">
                      <td>
                        <h5 class="text-truncate font-size-14 mb-0 interact" v-on:click="onEditPersonalShopperClicked(data)">
                          <a class="text-dark">{{
                            data.name
                          }}</a>
                        </h5>
                        <p class="text-muted mb-0 small interact" v-on:click="onEditPersonalShopperClicked(data)">{{ data.description }}</p>
                      </td>
                      <td class="text-center">{{ data.lastSync ? `${parseDateWithLocale(data.lastSync, {year: 'numeric', month: 'short', day: 'numeric'})} ${parseTimeWithLocale(data.lastSync, {hour: '2-digit', minute:'2-digit'})}` : '-'}}</td>
                      <td class="text-center">
                        <span class="badge font-size-10"
                          :class="{
                          'bg-success': `${data.status}` == 1,
                          'bg-warning': `${data.status}` == 0}">
                            {{ data.status == 1 ? $t('custom_strategies.status_active') : $t('custom_strategies.status_inactive') }}
                        </span>
											</td>
                      <td class="text-end">
                        <i class="fas text-primary me-2 interact" :class="data.status == 1 ? 'fa-pause' : 'fa-play'" v-on:click="onChangePersonalShopperStatus(data)"></i>
                        <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditPersonalShopperClicked(data)"></i>
                        <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemovePersonalShopper(data)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="rows > perPage">
          <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          @input="onPersonalShopperListPageClicked"
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                      ></b-pagination>
                  </ul>
              </div>
          </div>
        </div>
        <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemovePersonalShopperClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <p>{{ $t('personal_shoppers.remove_personal_shopper') }}</p>
        </b-modal>
        <b-modal v-model="showNewPersonalShopperModal" :title="$t('personal_shoppers.new')" :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')" @ok.prevent="onConfirmNewPersonalShopperClicked">
          <div>
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label>{{$t('personal_shoppers.name')}}*</label>
                  <input
                    v-model="ps.name"
                    name="name"
                    type="text"
                    :placeholder="$t('personal_shoppers.name_placeholder')"
                    class="form-control"
                    :class="{'is-invalid': submitted && $v.ps.name.$error,}"
                  />
                  <div v-if="submitted && !$v.ps.name.required" class="invalid-feedback">
                    {{$t('personal_shoppers.name_required')}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label>{{$t('personal_shoppers.description')}}*</label>
                  <input
                    v-model="ps.description"
                    name="name"
                    type="text"
                    :placeholder="$t('personal_shoppers.description_placeholder')"
                    class="form-control"
                    :class="{'is-invalid': submitted && $v.ps.description.$error,}"
                  />
                  <div v-if="submitted && !$v.ps.description.required" class="invalid-feedback">
                    {{$t('personal_shoppers.description_required')}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.interact {
  cursor: pointer;
}
</style>

<style>
#templates-modal .modal-body {
  background-color: var(--bs-body-bg);
}
</style>
