import i18 from "../i18n"

const _parseDateWithLocale = (params, options) => {
    switch(i18.locale){
        case 'es':
          return new Date(params).toLocaleDateString('es-AR',options) 
        case 'en':
          return new Date(params).toLocaleDateString('en-US',options) 
      }
  
      return '';    
}
  
const _parseTimeWithLocale  = (params,options) => {
    switch(i18.locale){
      case 'es':
        return new Date(params).toLocaleTimeString('es-AR',options)
      case 'en':
        return new Date(params).toLocaleTimeString('en-US',options)
    }

    return '';
  }
  
  const _parseMoneyWithLocale  = (params) => {
    if(typeof params === 'string' && !isNaN(parseFloat(params))){
      params = parseFloat(params);
    }else if(typeof params !== 'number'){
      return params;
    }
    switch(i18.locale){
      case 'es':
        return params.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      case 'en':
        return params.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    return '';
  }

  const _parseCountReduced = (params) => {

    if(typeof params === 'string' && !isNaN(parseFloat(params))){
      params = parseFloat(params);
    }else if(typeof params !== 'number'){
      return params;
    }

    if (params >= 1000000) {
      const millones = params / 1000000;
      return Math.floor(millones * 100) / 100 + "M";
    } else if (params >= 1000) {
      const miles = params / 1000;
      return Math.floor(miles * 100) / 100 + "K";
    } else {
      return params % 1 === 0 ? params.toFixed(0) : params.toFixed(2).replace(/\.?0*$/, "");
    }
  }

  const _parseMoneyReducedWithLocale  = (params) => {
    if(typeof params === 'string' && !isNaN(parseFloat(params))){
      params = parseFloat(params);
    }else if(typeof params !== 'number'){
      return params;
    }

    if (params >= 1000000) {
      const millones = params / 1000000;
      return Math.floor(millones * 100) / 100 + "M";
    } else if (params >= 1000) {
      const miles = params / 1000;
      return Math.floor(miles * 100) / 100 + "K";
    } else {
      return params.toFixed(2).replace(/\.?0*$/, "");
    }
  }

  const _parsePercentage = (params) => {
    return (params % 1 === 0) ? params.toFixed(0) : params.toFixed(2);
  }
  
  const _getSummaryByKey = (key, indicators)=>{

    if(indicators.length == 0){
      return 0; 
    }
    else{
      const values = indicators.reduce((sum, item) => {
        return sum + (parseFloat(item[key]|| 0));
      }, 0);
      
      return values
    }
  }
  
  const _calculatePercentageChange = (currentAmount, previousAmount)=> {

    if(typeof currentAmount === 'string'){
      currentAmount = parseFloat(currentAmount);
    }

    if(typeof previousAmount === 'string'){
      previousAmount = parseFloat(previousAmount);
    }

    if (previousAmount === 0) {
        if (currentAmount === 0) {
            return 0; 
        } else {
            return 100; 
        }
    }

    const percentageChange = ((currentAmount - previousAmount) / previousAmount) * 100;
    return Math.round(percentageChange);
  }

  const _subtractDays = (date, days)=> {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  const _getPreviousPeriod = (dateFrom, dateTo) => {
    
    const timeDifference = dateTo.getTime() - dateFrom.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24) + 1;
    
    const previousDateFrom = _subtractDays(dateFrom, daysDifference);
    const previousDateTo = _subtractDays(dateTo, daysDifference);

    return {
        previousDateFrom: previousDateFrom,
        previousDateTo: previousDateTo
    };
  }

  const _formatDate = (d)=> {
      
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) 
      month = '0' + month;
    if (day.length < 2) 
      day = '0' + day;
    
    return [year, month, day].join('-');
  }

  export default {
    parseDateWithLocale: _parseDateWithLocale,
    parseMoneyWithLocale: _parseMoneyWithLocale,
    parseTimeWithLocale: _parseTimeWithLocale,
    parsePercentage: _parsePercentage,
    parseMoneyReducedWithLocale: _parseMoneyReducedWithLocale,
    parseCountReduced: _parseCountReduced,
    getSummaryByKey: _getSummaryByKey,
    subtractDays: _subtractDays,
    getPreviousPeriod: _getPreviousPeriod,
    calculatePercentageChange: _calculatePercentageChange,
    formatDate: _formatDate
  }